
import { defineComponent, toRefs, ref } from "vue";
import { IonButton } from "@ionic/vue";

import DlvrInput from "@/components/DlvrInput.vue";

export default defineComponent({
  components: {
    DlvrInput,
    IonButton,
  },
  props: {
    onSave: Function,
    onCancel: Function,
    formData: Object,
    validationRules: Object,
  },
  emits: ["onAddressChanged"],
  setup(props, { emit }) {
    const { formData } = toRefs(props);
    const initialData = ref(formData);
    return {
      data: initialData,
      emit,
    };
  },
});

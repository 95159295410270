
import {
  defineComponent,
  onMounted,
  ref,
  toRefs,
  watch,
  reactive,
} from 'vue';
import {
  Camera,
  CameraResultType,
  CameraSource,
} from '@capacitor/camera';
import {
  IonContent,
  IonPage,
  IonBackButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonAvatar,
  IonToggle,
  IonIcon,
  IonButton,
  IonSpinner,
} from '@ionic/vue';
import {
  arrowForwardOutline,
  arrowForwardSharp,
  copy,
  lockClosedOutline,
  notificationsOutline,
  pencilSharp,
  linkOutline,
} from 'ionicons/icons';

import Joi from 'joi';
import { getDispatcherLocationGeoFireInstance } from '@/lib/firebase';
import { validateFields } from '@/lib/forms';
import { merchants } from '@/store';
import { loading, openToast } from '@/composables/ionicControllers';
import { getConfig } from '@/lib/constants';
import { Merchant, Pricing } from '@/types/merchant';
import { openAlert } from '@/composables/utils';
import { parseStateFromLocation } from '@/lib/helper';

import ContactDetails from './components/contactDetails.vue';
import ContactForm from './components/contactForm.vue';

const geoFire = getDispatcherLocationGeoFireInstance();

const IMG_BASE_URL =
  'https://firebasestorage.googleapis.com/v0/b/dlvr-automate.appspot.com/o';
const NO_IMG = `${IMG_BASE_URL}/no-img.png?alt=media`;

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonBackButton,
    IonButtons,
    IonButton,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonAvatar,
    IonToggle,
    IonIcon,
    ContactDetails,
    ContactForm,
    IonSpinner,
  },
  setup() {
    const formData = reactive({
      email: '',
      companyName: '',
      phone: '',
      address: '',
      // eslint-disable-next-line no-undef
      fullAddress: ref<google.maps.places.PlaceResult | null>(null),
    });
    const pricings = ref<Pricing[]>([]);
    const logo = ref('');
    const smsNotification = ref<boolean>(false);
    const bookDLVR = ref('');
    const isEditProfileFormOpen = ref(false);
    const isEditPasswordFormOpen = ref(false);
    const oldPassword = ref('');
    const newPassword = ref('');
    const confirmPassword = ref('');
    const isUploading = ref(false);
    const {
      updateMerchantProfile,
      retrieveMechantProfile,
      updateMerchantStore,
      getUploadUrl,
      getMerchantPricings,
    } = merchants;
    const { merchant } = toRefs(merchants.state);

    onMounted(async () => {
      if (!merchant) {
        await retrieveMechantProfile();
      }
      await getMerchantPricings();
      pricings.value = merchant.value?.pricings || [];
      formData.email = merchant.value?.email || '';
      formData.companyName = merchant.value?.companyName || '';
      formData.address = merchant.value?.address || '';
      logo.value = merchant.value?.logo || NO_IMG;
      smsNotification.value =
        merchant.value?.smsNotification || false;
      if (merchant.value?.phone?.startsWith('234')) {
        formData.phone = merchant.value?.phone?.substring(3, 13);
      } else {
        formData.phone = merchant.value?.phone || '';
      }
      bookDLVR.value =
        `${getConfig().APP_URL}/${merchant.value?.slug}/book` || '';
    });

    const copyToClipboard = () => {
      const cb = navigator.clipboard;
      cb.writeText(bookDLVR.value).then(() => openToast('Copied!'));
    };

    const takePhoto = async () => {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Photos,
        quality: 100,
      });

      const filename = new Date().getTime() + '.jpeg';

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const response = await fetch(cameraPhoto.webPath!);
      const blob = await response.blob();
      isUploading.value = true;
      const res = await getUploadUrl(filename);
      if (!res.ok) {
        isUploading.value = false;
        await openToast(res?.message as string);
      } else {
        const file = new File([blob], filename);
        await fetch(res.data, {
          method: 'PUT',
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-amz-acl': 'public-read',
          },
          body: file,
        })
          .then(async () => {
            logo.value = res.data.split('?')[0];
            updateMerchantStore({ logo: res.data.split('?')[0] });
            isUploading.value = false;
            await openToast('profile image updated successfully');
            return;
          })
          .catch(async () => {
            isUploading.value = false;
            await openToast('profile image upload failed!');
          });
      }
      return cameraPhoto;
    };

    watch(merchant, () => {
      if (merchant.value) {
        formData.email = merchant.value?.email || '';
        formData.companyName = merchant.value?.companyName || '';
        formData.address = merchant.value?.address || '';
        formData.phone = merchant.value?.phone || '';
        logo.value = merchant.value?.logo || NO_IMG;
      }
    });

    watch(formData, () => {
      bookDLVR.value = `${getConfig().APP_URL}/${formData.companyName
        .toLowerCase()
        .split(' ')
        .join('_')}/book`;
    });

    const validationRules = {
      profile: {
        companyName: Joi.string()
          .min(3)
          .required(),
        phone: Joi.string()
          .pattern(/\+?\d{10,13}/)
          .required(),
        address: Joi.string().required(),
      },
      password: {
        oldPassword: Joi.string().required(),
        newPassword: Joi.string()
          .pattern(new RegExp('^[a-zA-Z0-9]{3,30}$'))
          .required(),
      },
    };

    const profileDetailsSchema = Joi.object(validationRules.profile);
    const passwordDetailsSchema = Joi.object(
      validationRules.password,
    );

    const toggleNotification = (e: any) => {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
      const toggleHandler = async () => {
        const loader = await loading();
        await loader.present();
        const response: any = await updateMerchantProfile({
          smsNotification: !smsNotification.value,
        });
        if (!response.ok) {
          await openToast(response?.message);
          await loader.dismiss();
        } else {
          smsNotification.value = !smsNotification.value;
          await openToast(
            `SMS notification turned ${
              smsNotification.value ? 'ON' : 'OFF'
            }`,
          );
        }
        await loader.dismiss();
      };

      return !smsNotification.value
        ? openAlert(
            'turn on sms notification?. (charges may apply)',
            toggleHandler,
          )
        : openAlert('turn off sms notification?', toggleHandler);
    };

    const customValidation = () => {
      if (confirmPassword.value.length === 0) {
        return '"confirm password" is not allowed be empty';
      }
      if (newPassword.value !== confirmPassword.value) {
        return 'Passwords do not match';
      }
      return false;
    };

    const saveEdit = async () => {
      const {
        error,
        value,
      }: { error?: any; value: any } = validateFields(
        profileDetailsSchema,
        () => ({
          companyName: formData.companyName,
          phone: formData.phone,
          address: formData.address,
        }),
        false,
      );

      if (error) {
        return error;
      } else {
        const loader = await loading();
        const changedFields = Object.keys(value).reduce(
          (acc: Merchant, key: string) => {
            if (merchant?.value !== null) {
              if (
                merchant.value[key as keyof Merchant] !== value[key]
              ) {
                acc[key as keyof Merchant] = value[key];
              }
            }
            return acc;
          },
          {},
        );

        if (changedFields.address) {
          const { fullAddress } = formData;
          if (!fullAddress) {
            await openToast(
              'please select a valid address from the options provided',
            );
            return;
          } else {
            await loader.present();
            // Only set this if the merchant has setup pricing for the state in which they are located
            const state = parseStateFromLocation(fullAddress);
            const statePricing =
              state &&
              pricings.value.find(
                pricing =>
                  pricing.state?.toLowerCase() ===
                  state.toLowerCase(),
              );

            if (statePricing && merchant.value?.merchantId) {
              console.log(statePricing, merchant);
              geoFire
                .set('' + merchant.value?.merchantId, [
                  fullAddress?.geometry?.location.lat() as number,
                  fullAddress?.geometry?.location.lng() as number,
                ])
                .then(
                  function() {
                    console.log(
                      'Provided key has been added to GeoFire',
                    );
                  },
                  function(error) {
                    console.log('Error: ' + error);
                  },
                );
            } else {
              await geoFire.remove('' + merchant.value?.merchantId);
            }

            changedFields.latitude = fullAddress?.geometry?.location.lat();
            changedFields.longitude = fullAddress?.geometry?.location.lng();

            const response: any = await updateMerchantProfile({
              ...changedFields,
            });
            if (!response.ok) {
              await openToast(response?.message);
              await loader.dismiss();
            } else {
              await openToast(response.message);
              isEditProfileFormOpen.value = !isEditProfileFormOpen.value;
            }

            await loader.dismiss();
          }
          return;
        } else {
          await loader.present();
          const response: any = await updateMerchantProfile({
            ...changedFields,
          });
          if (!response.ok) {
            await openToast(response?.message);
            await loader.dismiss();
          } else {
            await openToast(response.message);
            isEditProfileFormOpen.value = !isEditProfileFormOpen.value;
          }

          await loader.dismiss();
        }
      }
    };

    const cancelEdit = () => {
      isEditProfileFormOpen.value = !isEditProfileFormOpen.value;
      const { merchant } = toRefs(merchants.state);
      formData.companyName = merchant.value?.companyName || '';
      formData.address = merchant.value?.address || '';
      formData.phone = merchant.value?.phone || '';
    };

    const savePasswordDetails = () => {
      const { error, value } = validateFields(
        passwordDetailsSchema,
        () => ({
          oldPassword: oldPassword.value,
          newPassword: newPassword.value,
        }),
        false,
      );

      if (error || customValidation()) {
        return { error: error || customValidation() };
      } else {
        return { ...value };
      }
    };

    return {
      pageTitle: 'Profile',
      isUploading,
      formData,
      merchant,
      logo,
      smsNotification,
      bookDLVR,
      oldPassword,
      newPassword,
      confirmPassword,
      saveEdit,
      savePasswordDetails,
      takePhoto,
      cancelEdit,
      copyToClipboard,
      copy,
      linkOutline,
      isEditProfileFormOpen,
      isEditPasswordFormOpen,
      arrowForwardOutline,
      arrowForwardSharp,
      lockClosedOutline,
      notificationsOutline,
      pencilSharp,
      validationRules,
      customValidation,
      toggleNotification,
    };
  },
});
